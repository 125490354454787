@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  font-family: sans-serif;

}

.service-header {
  font-family: Lobster, sans-serif;
}

.bgimg {
  background-image: url("./Images/bar-5.jpg");
  object-fit: cover;
  background-size: cover;

}

.poppins {
  font-family: Poppins, sans-serif;
}

.roboto {
  font-family: Roboto, sans-serif;
}


.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.hide-scroll-bar {

  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #FFF;
  border-radius: 1px;
}

::-webkit-scrollbar-thumb {
  background-color: gray;
  /* border-radius: 50px; */
}